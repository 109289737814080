<style scoped>
  .text-danger {
    color: red !important;
  }
</style>
<template>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-row">
        <div class="vx-col sm:w-1/1 w-full mb-base">
            <div class="vx-row">
                <!-- Customer Data -->
                <div class="vx-col sm:w-1/2 w-full mb-base">
                    <span class="py-2 h5 font-semibold">Customer Data</span><hr>
                    <!-- One time cust -->
                    <div class="vx-row my-6">
                        <div class="vx-col sm:w-1/6">
                            One Time
                        </div>
                        <div class="vx-col sm:w-2/6">
                            <vs-switch
                                color="dark"
                                icon-pack="feather"
                                vs-icon-on="icon-check-circle"
                                vs-icon-off="icon-slash"
                                v-model="isOneTime"
                                disabled
                                v-if="this.update"
                            >
                                <span slot="on">YES</span>
                                <span slot="off">NO</span>
                            </vs-switch>
                            <vs-switch
                                color="dark"
                                icon-pack="feather"
                                vs-icon-on="icon-check-circle"
                                vs-icon-off="icon-slash"
                                v-model="isOneTime"
                                v-else
                            >
                                <span slot="on">YES</span>
                                <span slot="off">NO</span>
                            </vs-switch>
                        </div>
                        <div v-if="this.isOneTime" class="vx-col sm:w-2/6">
                            <vs-button 
                                color="primary" 
                                type="border" 
                                icon-pack="feather" 
                                icon="icon-eye" 
                                size="small" 
                                @click="showOTForm()"
                            >
                                Show Form Data
                            </vs-button>
                        </div>
                    </div>
                    <div class="vx-row my-6">
                        <div class="vx-col sm:w-1/6">
                            <span>Customer ID</span>
                        </div>
                        <div class="vx-col vx-row sm:w-5/6">
                            <div class="vx-col sm:w-5/6 w-full">
                                <vs-input class="w-full" v-model="salesOrder.Customer.Code" disabled/>
                            </div>
                            <div v-if="!this.isOneTime" class="vx-col sm:w-1/6 w-full">
                                <vs-button size="small" class="mr-3 mb-2" @click="browseCustomer()">Browse</vs-button>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row my-6">
                        <div class="vx-col sm:w-1/6">
                            <span>Customer Name</span>
                        </div>
                        <div class="vx-col vx-row sm:w-5/6">
                            <div class="vx-col sm:w-5/6 w-full">
                                <vs-input class="w-full" v-model="salesOrder.Customer.Name" disabled/>
                            </div>
                            <div class="vx-col sm:w-1/6 w-full">&nbsp;</div>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6">
                            <span>Bill to</span>
                        </div>
                        <div class="vx-col vx-row sm:w-5/6">
                            <div class="vx-col sm:w-5/6 w-full">
                                <vs-input class="w-full" v-model="salesOrder.BillAddress.Address" disabled/>
                            </div>
                            <div v-if="!this.isOneTime" class="vx-col sm:w-1/6 w-full">
                                <vs-button size="small" class="mr-3 mb-2" @click="browseBillTo()">Browse</vs-button>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6">
                            <span>Ship to</span>
                        </div>
                        <div class="vx-col vx-row sm:w-5/6">
                            <div class="vx-col sm:w-5/6 w-full">
                                <vs-input class="w-full" v-model="salesOrder.ShipAddress.Address" disabled/>
                            </div>
                            <div v-if="!this.isOneTime" class="vx-col sm:w-1/6 w-full">
                                <vs-button size="small" class="mr-3 mb-2" @click="browseShipTo()">Browse</vs-button>
                            </div>
                        </div>
                    </div>
                    <!-- Customer One time Form -->
                    <div v-if="this.isOneTime && showOTCForm" class="vx-row mb-2 mt-4">
                        <div class="vx-col w-full">
                            <span class="py-2 h5 font-semibold">One Time Customer Data</span>
                            <span role="button" class="float-right" @click="handleClose">Close</span>
                            <hr>
                        </div>
                        <div class="vx-col w-full">
                            <div class="vx-row my-6">
                                <div class="vx-col w-full">
                                    <label class="vs-input--label">Customer Name <span class="text-danger">*</span></label>
                                    <vs-input class="w-full" v-model="oneTimeCust.name" placeholder="Input name" name="Customer Name"/>
                                </div>
                            </div>
                            <address-component
                                :data="oneTimeCust.address"
                                @data="setDataAddress"
                            ></address-component>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <label class="vs-input--label">Contact Person <span class="text-danger">*</span></label>
                                    <vs-input class="w-full" v-model="oneTimeCust.contact_person" placeholder="Input contact person"/>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <label class="vs-input--label">NIK <span class="text-danger">*</span></label>
                                    <vs-input class="w-full" v-model="oneTimeCust.nik" placeholder="Input NIK"/>
                                </div>
                            </div>                            
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <label class="vs-input--label">Phone Number <span class="text-danger">*</span></label>
                                    <vs-input class="w-full" v-model="oneTimeCust.phone" placeholder="Input phone number"/>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <!-- <vs-input class="w-full" label="Tax ID" v-model="oneTimeCust.tax_id" placeholder="Input Tax ID"/> -->
                                    <label class="vs-input--label">Tax ID</label>
                                    <multiselect
                                            class="selectExample"
                                            v-model="selectTax"
                                            :options="optionTax"
                                            :multiple="false"
                                            :allow-empty="false"
                                            :group-select="false"
                                            :max-height="120"
                                            :limit="3"
                                            placeholder=" Type to search"
                                            track-by="Name"
                                            label="Name"
                                            :disabled="false"
                                        >

                                        <template slot="singleLabel" slot-scope="dt">
                                            <span class="option__desc">
                                                <span class="option__title">{{ dt.option.Name }}</span>
                                            </span>
                                        </template>

                                        <template slot="option" slot-scope="dt">
                                            <div class="option__desc">
                                                <span class="option__title">{{ dt.option.Name }}</span>
                                            </div>
                                        </template>

                                    </multiselect>
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <vs-button size="medium" v-on:click="handleCustOnetime" color="primary">
                                        {{ this.oneTimeCust.id != null ? "Update" : "Confirm" }}
                                    </vs-button>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
                <!-- Segmentation Data -->
                <div class="vx-col sm:w-1/2 w-full mb-base">
                    <span class="py-2 h5 font-semibold">Segmentation Data</span><hr>
                    <div class="vx-row my-6">
                        <div class="vx-col sm:w-1/6 w-full flex items-center">
                            <span>Territory</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                class="selectExample"
                                v-model="territory"
                                :options="optionTerritory"
                                :multiple="false"
                                :allow-empty="false"
                                :group-select="false"
                                :max-height="120"
                                :limit="3"
                                placeholder="Type to search"
                                track-by="code"
                                label="Name"
                            >

                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc">
                                        <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                    </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                    </div>
                                </template>

                            </multiselect>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full flex items-center">
                            <span>Zone</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                v-model="zone"
                                :options="optionZones"
                                placeholder="Type to search"
                                track-by="name"
                                label="name"
                                :max-height="125"
                            >
                                <span slot="noResult">Oops! No data found</span>
                                <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title"
                                    >{{ props.option.code }} {{ props.option.name }}</span
                                    >
                                </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title"
                                    >{{ props.option.code }} {{ props.option.name }}</span
                                    >
                                </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full flex items-center">
                            <span>Sales Channel</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                class="selectExample"
                                v-model="salesChannel"
                                :options="optionSalesChannel"
                                :multiple="false"
                                :allow-empty="false"
                                :group-select="false"
                                :max-height="120"
                                :limit="3"
                                placeholder="Type to search"
                                track-by="code"
                                label="name"
                            >

                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc">
                                        <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                    </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                    </div>
                                </template>

                            </multiselect>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full flex items-center">
                            <span>Distribution Channel</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                class="selectExample"
                                v-model="customerCategory"
                                :options="optionCustomerCategory"
                                :multiple="false"
                                :allow-empty="false"
                                :group-select="false"
                                :max-height="120"
                                :limit="3"
                                placeholder="Type to search"
                                track-by="ID"
                                label="name"
                            >

                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                    </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                    </div>
                                </template>

                            </multiselect>
                        </div>
                    </div>
                    <!-- Sales ID here -->
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full flex items-center">
                            <span>Sales ID</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                    class="selectExample"
                                    v-model="salesPersonal"
                                    :options="optionSalesPersonal"
                                    :multiple="false"
                                    :allow-empty="true"
                                    :max-height="120"
                                    :limit="3"
                                    placeholder="Type to search"
                                    track-by="ID"
                                    label="Name"
                                >

                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title">{{ props.option.Name }}</span>
                                        </span>
                                    </template>

                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <span class="option__title">{{ props.option.Name }}</span>
                                        </div>
                                    </template>
                            </multiselect>
                        </div>
                    </div>
                    <!-- Pricing Group here -->
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full flex items-center">
                            <span>Pricing Group</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                v-model="pricingGroup.selected"
                                :options="pricingGroup.options"
                                placeholder="Type to search"
                                track-by="name"
                                label="name"
                                :max-height="125"
                                >
                                <span slot="noResult">Oops! No data found</span>
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc">
                                    <span class="option__title"
                                        >{{ props.option.name }} ({{ props.option.code }})</span
                                    >
                                    </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                    <span class="option__title"
                                        >{{ props.option.name }} ({{ props.option.code }})</span
                                    >
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Holder browse data -->
            <div v-if="this.optionCustomerShow || this.optionBillToShow || this.optionShipToShow || this.detailStepShow || this.detailTotalDiscountStepShow" class="vx-row">
                <div v-if="this.optionCustomerShow" class="vx-col w-full mb-base">
                    <hr><hr><br>
                    <div class="vx-row mb-3">
                        <div class="vx-col w-11/12"><h3>Customer</h3></div>
                        <div class="vx-col w-1/12"><vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather" icon="icon-x-square">Close </vs-button></div>
                    </div>
                    <!-- Customer Browse -->
                    <div v-if="this.optionCustomerShow && !this.isOneTime" class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <vs-dropdown vs-trigger-click class="cursor-pointer">
                                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>                
                                    <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                        <span>{{item}}</span>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                        <div class="mx-auto"></div>
                        <div class="vx-col sm:w-1/3 w-full">
                            <div class="flex flex-wrap items-center justify-between">
                                <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name or City " />
                            </div>
                        </div>
                    </div>
                    <!-- Customer Browse -->
                    <div v-if="this.optionCustomerShow && !this.isOneTime" class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="optionCustomer" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Code</vs-th>
                                    <vs-th>Name</vs-th>
                                    <vs-th>Address</vs-th>
                                    <vs-th>Category</vs-th>
                                    <vs-th>Action</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td>{{dt.code}}</vs-td>
                                        <vs-td>{{dt.name}}</vs-td>                                
                                        <vs-td>
                                            City : {{dt.city}} <br>
                                            Address : {{dt.address}}
                                        </vs-td>
                                        <vs-td>{{dt.customer_category_name}}</vs-td>
                                        <vs-td style="align-item-center">
                                            <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectCustomer(dt)"/>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                            <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                        </div>
                    </div>
                </div>
                
                <!-- <div v-if="this.optionReservationCodeShow" class="vx-col w-full mb-base">
                    <datatableReservationCode :territoryId="territoryId" :selectedReservationCode="salesOrder.ReservationCode" v-on:selectReservationCode="updateSelectedReservationCode"></datatableReservationCode>
                </div> -->                

                <div v-if="this.optionBillToShow" class="vx-col w-full mb-base">
                    <hr><hr><br>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-11/12"><h3>Customer Address - Bill To</h3></div>
                        <div class="vx-col w-1/12"><vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather" icon="icon-x-square">Close </vs-button></div>
                    </div>
                    <!-- Browse Billto -->
                    <div v-if="!this.isOneTime" class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <vs-dropdown vs-trigger-click class="cursor-pointer">
                                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>                
                                    <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                        <span>{{item}}</span>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                        <div class="mx-auto"></div>
                        <div class="vx-col sm:w-1/3 w-full">
                            <div class="flex flex-wrap items-center justify-between">
                                <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name or City " />
                            </div>
                        </div>
                    </div>
                    <div v-if="!this.isOneTime" class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="optionBillTo" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Address</vs-th>
                                    <vs-th>Map</vs-th>
                                    <vs-th>Contact</vs-th>
                                    <vs-th>Work Operation</vs-th>
                                    <vs-th>Action</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td>
                                            Address : {{ dt.address }}<br>
                                            Subdistrict : {{ dt.sub_district }}<br>
                                            District : {{ dt.district }}<br>
                                            City : {{ dt.city }}<br>
                                            Province : {{ dt.province }}<br>
                                            Country : {{ dt.country }}<br>
                                            Postal Code : {{ dt.postal_code }}
                                        </vs-td>
                                        <vs-td>
                                            Address : {{ dt.map_address }}<br>
                                            Lat : {{ dt.latitude }}<br>
                                            Long : {{ dt.longitude }}
                                        </vs-td>                                
                                        <vs-td>
                                            Name : {{ dt.contact_name }}<br>
                                            Phone : {{ dt.phone }}<br>
                                            Mobile : {{ dt.contact_mobile }}<br>
                                            Email : {{ dt.email }}<br>
                                            Fax : {{ dt.fax }}
                                        </vs-td>
                                        <vs-td>
                                            <div v-if="dt.work_operation">
                                                <div v-for="item in dt.work_operation.work_operation_detail" :key="item.id" >
                                                    {{item.work_day.day}} ({{item.work_time.start_time}} - {{item.work_time.end_time}}) <br>
                                                </div>
                                            </div>
                                        </vs-td>
                                        <vs-td style="align-item-center">
                                            <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectBillTo(dt)"/>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                            <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                        </div>
                    </div>
                </div>

                <div v-if="this.optionShipToShow" class="vx-col w-full mb-base">
                    <hr><hr><br>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-11/12"><h3>Customer Address - Ship To</h3></div>
                        <div class="vx-col w-1/12"><vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather" icon="icon-x-square">Close </vs-button></div>
                    </div>
                    <div v-if="!this.isOneTime" class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <vs-dropdown vs-trigger-click class="cursor-pointer">
                                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>                
                                    <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                        <span>{{item}}</span>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                        <div class="mx-auto"></div>
                        <div class="vx-col sm:w-1/3 w-full">
                            <div class="flex flex-wrap items-center justify-between">
                                <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name or City " />
                            </div>
                        </div>
                    </div>
                    <div v-if="!this.isOneTime" class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="optionShipTo" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Address</vs-th>
                                    <vs-th>Map</vs-th>
                                    <vs-th>Contact</vs-th>
                                    <vs-th>Work Operation</vs-th>
                                    <vs-th>Action</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td>
                                            Address : {{ dt.address }}<br>
                                            Subdistrict : {{ dt.sub_district }}<br>
                                            District : {{ dt.district }}<br>
                                            City : {{ dt.city }}<br>
                                            Province : {{ dt.province }}<br>
                                            Country : {{ dt.country }}<br>
                                            Postal Code : {{ dt.postal_code }}
                                        </vs-td>
                                        <vs-td>
                                            Address : {{ dt.map_address }}<br>
                                            Lat : {{ dt.latitude }}<br>
                                            Long : {{ dt.longitude }}
                                        </vs-td>                                
                                        <vs-td>
                                            Name : {{ dt.contact_name }}<br>
                                            Phone : {{ dt.phone }}<br>
                                            Mobile : {{ dt.contact_mobile }}<br>
                                            Email : {{ dt.email }}<br>
                                            Fax : {{ dt.fax }}
                                        </vs-td>
                                        <vs-td>
                                            <div v-if="dt.work_operation">
                                                <div v-for="item in dt.work_operation.work_operation_detail" :key="item.id" >
                                                    {{item.work_day.day}} ({{item.work_time.start_time}} - {{item.work_time.end_time}}) <br>
                                                </div>
                                            </div>
                                        </vs-td>
                                        <vs-td style="align-item-center">
                                            <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectShipTo(dt)"/>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                            <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                        </div>
                    </div>
                </div>

                <div v-if="this.detailStepShow" class="vx-col w-full mb-base">
                    <div v-if="(typeof pricingStep[indexStep]) != 'undefined'">
                        <hr><hr><br>
                        <div class="vx-row mb-6">
                            <div class="vx-col w-11/12"><h3>Price Engine - Price (Model : Step Ladder)</h3></div>
                            <div class="vx-col w-1/12"><vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather" icon="icon-x-square">Close </vs-button></div>
                        </div>
                        <div class="vx-row mb-6">
                            <div class="vx-col w-full">
                                <vs-table :data="pricingStep[indexStep]" stripe style="width:100%">
                                    <template slot="thead">
                                        <vs-th>Step</vs-th>
                                        <vs-th>Qty</vs-th>
                                        <vs-th>Price</vs-th>
                                        <vs-th>Qty x Price</vs-th>
                                    </template>

                                    <template slot-scope="{data}">
                                        <vs-tr :key="index" v-for="(dt, index) in data">
                                            <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                            <vs-td style="text-align:right">{{ dt.qty }}</vs-td>
                                            <vs-td style="text-align:right">{{ formatCurrency(dt.price) }}</vs-td>
                                            <vs-td style="text-align:right">{{ formatCurrency(dt.qty * dt.price) }}</vs-td>
                                        </vs-tr>
                                    </template>
                                </vs-table>
                            </div>
                        </div>
                    </div>
                    <div v-if="(typeof discountStep[indexStep]) != 'undefined'">
                        <hr><hr><br>
                        <div class="vx-row mb-6">                
                            <h3>Price Engine - Discount (Model : Step Ladder - Unit : Line)</h3>
                        </div>
                        <div class="vx-row mb-6">
                            <div class="vx-col w-full">
                                <vs-table :data="discountStep[indexStep]" stripe style="width:100%">
                                    <template slot="thead">
                                        <vs-th>Step</vs-th>
                                        <vs-th>Nominal</vs-th>
                                        <vs-th>Percentage</vs-th>
                                        <vs-th>Amount</vs-th>                                
                                    </template>

                                    <template slot-scope="{data}">
                                        <vs-tr :key="index" v-for="(dt, index) in data">
                                            <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                            <vs-td style="text-align:right">{{ formatCurrency(dt.nominal) }}</vs-td>
                                            <vs-td style="text-align:right">{{ dt.percentage }}%</vs-td>
                                            <vs-td style="text-align:right">{{ formatCurrency(dt.amount) }}</vs-td>
                                        </vs-tr>
                                    </template>
                                </vs-table>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                        <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                            <span>Sub Total Line</span>
                        </div>
                        <div class="vx-col sm:w-1/5 w-full">
                            <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsSubtotalEngine[indexStep])" disabled />
                        </div>
                    </div>
                    <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                        <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                            <span>Total Discount Line</span>
                        </div>
                        <div class="vx-col sm:w-1/5 w-full">
                            <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsDiscountEngine[indexStep])" disabled />
                        </div>
                    </div>
                    <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                        <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                            <span>Tax Line</span>
                        </div>
                        <div class="vx-col sm:w-1/5 w-full">
                            <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsTaxEngine[indexStep])" disabled />
                        </div>
                    </div>
                    <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                        <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                            <span>Total Line</span>
                        </div>
                        <div class="vx-col sm:w-1/5 w-full">
                            <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsLineTotalEngine[indexStep])" disabled />
                        </div>
                    </div>
                </div>

                <div v-if="this.detailTotalDiscountStepShow" class="vx-col w-full mb-base">
                    <div class="vx-row mb-6">
                        <div class="vx-col w-11/12"><h3>Price Engine - Discount (Model : Step Ladder - Unit : Total)</h3></div>
                        <div class="vx-col w-1/12"><vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather" icon="icon-x-square">Close </vs-button></div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="totalDiscountStep" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Step</vs-th>
                                    <vs-th>Nominal</vs-th>
                                    <vs-th>Percentage</vs-th>
                                    <vs-th>Amount</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                        <vs-td style="text-align:right">{{ formatCurrency(dt.nominal) }}</vs-td>
                                        <vs-td style="text-align:right">{{ formatCurrency(dt.percentage) }}%</vs-td>
                                        <vs-td style="text-align:right">{{ formatCurrency(dt.amount) }}</vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </div>
                    <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                        <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                            <span>Total Discount</span>
                        </div>
                        <div class="vx-col sm:w-1/5 w-full">
                            <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalDiscount" disabled />
                        </div>
                    </div>
                </div>            
            </div>
            <!--  -->
            <div class="vx-row">
                <!-- SO Data -->
                <div class="vx-col sm:w-1/2 w-full mb-base">
                    <span class="pt-2 h5 font-semibold">SO Data</span><hr>
                    <div class="vx-row my-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>SO Code</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <vs-input class="w-full" v-model="salesOrder.Code" disabled/>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>Ref. PO Code</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <vs-input class="w-full" v-model="salesOrder.PoReferenceCode"/>
                        </div>
                    </div>
                    <!-- SO Type here -->
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>SO Type</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <!-- <vs-input class="w-full" v-model="salesOrder.Code" disabled/> -->
                            <multiselect
                                class="selectExample"
                                v-model="soType"
                                :options="optionSoType"
                                :multiple="false"
                                :allow-empty="false"
                                :group-select="false"
                                :max-height="120"
                                :limit="4"
                                placeholder="Type to search"
                                track-by="desc"
                                label="desc"
                            >

                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                    </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                    </div>
                                </template>

                            </multiselect>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>SO Date</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <datepicker :inline="false" v-model="salesOrder.soDate" placeholder="Select Document Due Date"></datepicker>
                            <!-- <flat-pickr class="w-full" :inline="false" :config="configFlatPickrDate" v-model="salesOrder.soDate" placeholder="Select Document Date"></flat-pickr> -->
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>Payment Term</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                    class="selectExample"
                                    v-model="selectPaymentTerm"
                                    :options="optionPaymentTerm"
                                    :multiple="false"
                                    :allow-empty="false"
                                    :group-select="false"
                                    :max-height="120"
                                    :limit="3"
                                    placeholder=" Type to search"
                                    track-by="id"
                                    label="name"
                                    :disabled="false"
                                >
                                <template slot="singleLabel" slot-scope="dt">
                                    <span class="option__desc">
                                        <span class="option__title">{{ dt.option.name }}</span>
                                    </span>
                                </template>

                                <template slot="option" slot-scope="dt">
                                    <div class="option__desc">
                                        <span class="option__title">{{ dt.option.name }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>Tax</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                    class="selectExample"
                                    v-model="selectTax"
                                    :options="optionTax"
                                    :multiple="false"
                                    :allow-empty="false"
                                    :group-select="false"
                                    :max-height="120"
                                    :limit="3"
                                    placeholder=" Type to search"
                                    track-by="Name"
                                    label="Name"
                                    :disabled="false"
                                >

                                <template slot="singleLabel" slot-scope="dt">
                                    <span class="option__desc">
                                        <span class="option__title">{{ dt.option.Name }}</span>
                                    </span>
                                </template>

                                <template slot="option" slot-scope="dt">
                                    <div class="option__desc">
                                        <span class="option__title">{{ dt.option.Name }}</span>
                                    </div>
                                </template>

                            </multiselect>
                        </div>
                    </div>
                    <!-- Promotion Code -->
                    <!-- <fieldPromotionCode v-if="this.salesOrder.Customer.Code != '' && this.salesOrder.Customer.Code != 0 && this.salesOrder.Customer.Code != null" :promotionCode="promotionCode" v-on:browsePromotionCode="browsePromotionCode"></fieldPromotionCode> -->
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>Line Items</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <vs-button size="small" class="mr-3 mb-2" @click="browseItem()">Browse</vs-button>
                        </div>
                    </div>
                    <div v-if="this.PriceModel != ''">Price Model : {{this.PriceModel}}</div>&nbsp;
                    <div v-if="this.DiscountModel != ''">Discount Model : {{this.DiscountModel}}</div>&nbsp;
                    <div v-if="this.DiscountUnit != ''">Discount Unit : {{this.DiscountUnit}}</div>&nbsp;
                    <div v-if="this.priceProposal != ''">Price Proposal : {{this.priceProposal}}</div>&nbsp;
                </div>
                <!-- Delivery Data -->
                <div class="vx-col sm:w-1/2 w-full mb-base">
                    <span class="pt-2 h5 font-semibold">Delivery Data</span><hr>
                    <div class="vx-row my-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>Allow Partial</span>
                        </div>
                        <div class="vx-col vx-row sm:w-5/6 w-full">
                            <div class="vx-col sm:w-1/6">
                                <input type="radio" value="1" v-model="salesOrder.AllowPartial">
                                <label> Yes</label>
                            </div>
                            <div class="vx-col sm:w-1/6">
                                <input type="radio" value="0" v-model="salesOrder.AllowPartial">
                                <label> No</label>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>Delivery Type</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <multiselect
                                    class="selectExample"
                                    v-model="selectDeliveryType"
                                    :options="optionDeliveryType"
                                    :multiple="false"
                                    :allow-empty="false"
                                    :group-select="false"
                                    :max-height="120"
                                    :limit="3"
                                    placeholder=" Type to search"
                                    track-by="id"
                                    label="name"
                                    :disabled="false"
                                >
                                <template slot="singleLabel" slot-scope="dt">
                                    <span class="option__desc">
                                        <span class="option__title">{{ dt.option.name }}</span>
                                    </span>
                                </template>

                                <template slot="option" slot-scope="dt">
                                    <div class="option__desc">
                                        <span class="option__title">{{ dt.option.name }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/6 w-full">
                            <span>Delivery Date</span>
                        </div>
                        <div class="vx-col sm:w-5/6 w-full">
                            <datepicker :inline="false" v-model="salesOrder.DueDate" placeholder="Select Delivery Date"></datepicker>
                            <!-- <flat-pickr class="w-full" :inline="false" :config="configFlatPickrDueDate" v-model="DueDate" placeholder="Select Document Due Date"></flat-pickr> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="this.optionPromotionCodeShow || this.optionItemAvailableShow" class="vx-col sm:w-2/2 w-full mb-base" style="max-height: 475px; overflow-y: scroll;">
            <!-- Promotion Code & Items Available -->
            <!-- <div v-if="this.optionPromotionCodeShow" class="vx-col w-full mb-base">
                <hr><hr><br>
                <div class="vx-row mb-6">
                    <div class="vx-col w-11/12"><h3>Promotion Code</h3></div>
                    <div class="vx-col w-1/12"><vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather" icon="icon-x-square">Close </vs-button></div>
                </div>
                <datatablePromotionCode :territoryId="territoryId" :selectedPromotionCode="promotionCode" v-on:selectPromotionCode="updateSelectedPromotionCode" v-on:limitItemByPromotion="updateLimitItemByPromotion"></datatablePromotionCode>
            </div> -->

            <!--  -->
            <div v-if="this.optionItemAvailableShow" class="vx-col w-full mb-base">
                <hr><hr><br>
                <div class="vx-row mb-6">
                    <div class="vx-col w-11/12"><h3>Item Available</h3></div>
                    <div class="vx-col w-1/12"><vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather" icon="icon-x-square">Close </vs-button></div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name, or UOM " />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="optionItemAvailable" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>SKU Code</vs-th>
                                <vs-th>Name</vs-th>
                                <vs-th>Action</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td>{{dt.sku_code}}</vs-td>
                                    <vs-td style="align-item-center">{{dt.item_name}}</vs-td>
                                    <vs-td style="align-item-center">
                                        <feather-icon title="Add" icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addItemLine(dt)"/>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-col w-full mb-base">
            <hr><hr><br>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <h4>SO Line Items Data</h4>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <!-- New So Line list -->
                    <table class="table table-stripe" style="width:100%">
                        <thead>
                            <tr>
                                <th style="vertical-align: middle;" rowspan="2">No</th>
                                <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                                <th style="vertical-align: middle; width:250px; max-width: 250px;" rowspan="2">Name</th>
                                <th style="vertical-align: middle;" rowspan="2">HU Label</th>
                                <th style="text-align:center;" colspan="2">Qty</th>
                                <th style="vertical-align: middle; text-align:center;" rowspan="2" colspan="2">List Price</th>
                                <th style="vertical-align: middle; text-align:center;" rowspan="2">Reg. Discount</th>
                                <!-- <th style="vertical-align: middle; text-align:center;" rowspan="2">Promotion</th> -->
                                <th style="vertical-align: middle; text-align:center;" rowspan="2">Tax</th>
                                <th style="vertical-align: middle; text-align:center;" rowspan="2">Action</th>
                            </tr>
                            <tr>
                                <th style="text-align:center; width:100px; max-width: 100px;">HU</th>
                                <th style="text-align:center; width:100px; max-width: 100px;">UOM</th>
                            </tr>
                        </thead>
                        <tbody>
                                <!-- Struktur baru, array-of-object -->
                                <template>
                                    <vs-tr :key="index" v-for="(dt, index) in salesOrderLines">
                                        <vs-td style="align-item-right; vertical-align: middle;">{{index+1}}</vs-td>
                                        <vs-td style="align-item-center; vertical-align: middle;">{{dt.sku_code}}</vs-td>
                                        <vs-td style="align-item-center; vertical-align: middle;">{{dt.sku_name}}</vs-td>
                                        <vs-td style="vertical-align: middle;">
                                            <span :key="i" v-for="(dt, i) in dt.hu_labels">
                                                {{ dt["unit"] + "." }}
                                            </span>
                                        </vs-td>
                                        <vs-td style="vertical-align: middle;">
                                            <vs-input v-model="salesOrderLines[index].items_hu" class="w-full" placeholder="" @input="calc_itemsLineQty(index)"/>
                                        </vs-td>
                                        <!-- <vs-td>{{itemsUom[index]}}</vs-td> -->
                                        <vs-td style="vertical-align: middle;">
                                            <vs-input v-model="salesOrderLines[index].items_qty" class="w-full" placeholder="" @input="calc_itemsLineQtyConv(index)"/>
                                        </vs-td>
                                        <!-- List Price -->
                                        <vs-td v-if="dt.list_price.length > 0" style="vertical-align: middle; text-align:right;">
                                            <span :key="i" v-for="(lp, i) in dt.list_price">
                                                {{ dt.qty_hu[i] + ' (' + dt.hu_labels[i].unit + ') @' + formatCurrency(lp) + ' :' }} <br>
                                            </span>
                                            <b>Subtotals : </b>
                                        </vs-td>
                                        <vs-td v-if="dt.list_price.length > 0" style="vertical-align: middle; text-align:right;">
                                            <span :key="i" v-for="(sp, i) in dt.subtotal.price">
                                                {{ formatCurrency(sp) }} <br>
                                            </span>
                                            {{ formatCurrency(dt.subtotal.all_price) }}
                                        </vs-td>
                                        <vs-td v-else style="vertical-align: middle; text-align:center;" colspan="2">
                                            {{ "-" }}
                                        </vs-td>
                                        <!-- Reg Discount -->
                                        <vs-td v-if="dt.reg_disc.length > 0" style="vertical-align: middle; text-align:right;">
                                            <span :key="i" v-for="(rd, i) in dt.reg_disc">
                                                {{ formatCurrency(rd) }} <br>
                                            </span>
                                            {{ formatCurrency(dt.subtotal.disc) }}
                                        </vs-td>
                                        <vs-td v-else style="vertical-align: middle; text-align:center;">
                                            {{ "-" }}
                                        </vs-td>
                                        <!-- Promotion belum ready -->
                                        <!-- <vs-td v-if="dt.promotion.length > 0" style="vertical-align: middle; text-align:right;">
                                            <span :key="i" v-for="(pr, i) in dt.promotion">
                                                {{ pr }} <br>
                                            </span>
                                        </vs-td>
                                        <vs-td v-else style="vertical-align: middle; text-align:center;">
                                            {{ "-" }}
                                        </vs-td> -->
                                        <!-- Tax -->
                                        <vs-td v-if="dt.tax.length > 0" style="vertical-align: middle; text-align:right;">
                                            <span :key="i" v-for="(tx, i) in dt.tax">
                                                {{ formatCurrency(tx) }} <br>
                                            </span>
                                            {{ formatCurrency(dt.subtotal.tax) }}
                                        </vs-td>
                                        <vs-td v-else style="vertical-align: middle; text-align:center;">
                                            {{ "-" }}
                                        </vs-td>
                                        <vs-td style="align-item-center; vertical-align: middle;">
                                            <feather-icon v-if="pricingStep.length > 0"  title="Detail" icon="SearchIcon" svgClasses="w-5 h-5 text-info stroke-current" class="ml-2" @click="detailStep(index)"/>
                                            <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteItemLine(index)"/>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- total order = harga satuan dikali jumlah 
            total diskon = totalnya diskon
            total nett = total order - total diskon
            total pajak = total nett * pajak
            total bayar = total nett + total pajak -->

            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Order</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBrutto" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Discount</span>
                    <feather-icon v-if="totalDiscountStep.length > 0"  title="Detail" icon="SearchIcon" svgClasses="w-5 h-5 text-info stroke-current" class="ml-2" @click="detailTotalDiscountStep()"/>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalDiscount" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Nett</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalNetto" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Tax</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalTax" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Charges</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input @keypress="isNumber($event)" @change="changeCharge()" class="vs-inputx vs-input--input" style="text-align:right" v-model="salesOrder.SubtotalCharge"/>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBilling" disabled />
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-6/12 w-full">
                    <vs-button v-if="calculated" class="mr-3 mb-2" v-on:click="SubmitForm()">Submit</vs-button>
                    <vs-button v-if="calculated != true" class="mr-3 mb-2" v-on:click="CalculateSOLine()">Calculate</vs-button>
                    <vs-button v-if="update" color="warning" class="mr-3 mb-2" @click="ResetForm()">Reset</vs-button>
                    <vs-button v-if="update" color="danger" class="mr-3 mb-2" @click="CancelForm()">Cancel</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import flatPickr from 'vue-flatpickr-component';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css';
import fieldPromotionCode from '../component/field_promotion_code.vue'
import datatablePromotionCode from "../component/datatable_promotion_code.vue";
import AddressComponent from "@/components/master/Address";

// import fieldReservationCode from '../component/field_reservation_code.vue'
// import datatableReservationCode from "../component/datatable_reservation_code.vue";
// import moment from 'moment'

export default {
    props: ["selectedSO"],
    components: {
        Datepicker,
        VueNumberInput,
        flatPickr,
        fieldPromotionCode,
        datatablePromotionCode,
        AddressComponent,
    },
    async mounted(){
        await this.getOptionTerritory();
        await this.getOptionSalesChannel();
        await this.getOptionCustomerCategory();
        await this.getAllDataPricingGroup();
        await this.getOptionZones();
        await this.getOptionSalesID();
        await this.getOptionTax();
        await this.getOptionPaymentTerm();
        await this.getOptionDeliveryType();

        // console.log("userLogin : ", this.$userLogin);
        if(this.selectedSO && this.selectedSO.Code) {
            // console.log('this.selectedSO',this.selectedSO);
            this.resetSalesOrder = Object.assign({}, this.selectedSO);
            this.salesOrder = this.selectedSO;
            
            this.salesOrder.soDate = new Date(this.salesOrder.Date)
            this.salesOrder.DueDate = new Date(this.salesOrder.DueDate)

            this.update = true;
            // this.external = true;
            this.external = false; // sementara dibikin true, belom ada object saleschannel di api SO
            this.territory = {
                code: this.selectedSO.Territory.Code,
                name: this.selectedSO.Territory.Name
            };
            this.selectTax = {
                Name: this.selectedSO.Tax.Name,
                Rate: this.selectedSO.Tax.Rate
            };

            if(this.selectedSO.SalesChannel.PriceProposal == "Allow") {
                this.priceProposal = "Allow";
            } else {
                this.priceProposal = "Disallow";
            }

            // Resp data NewtempSalesOrderLine
            for (var h = 0; h < this.salesOrder.NewtempSalesOrderLine.length; h++) {
                this.salesOrder.NewtempSalesOrderLine[h].items_hu = this.salesOrder.NewtempSalesOrderLine[h].qty_hu.join('.');
                this.salesOrder.NewtempSalesOrderLine[h].promotion = [];
                this.salesOrderLines.push(this.salesOrder.NewtempSalesOrderLine[h]);
            }

            for (var i = 0; i < this.salesOrder.SalesOrderLine.length; i++) {
                // Old data, array terpisah, let it be, karena belum dimodif, bingung :(
                this.itemsCode.push(this.salesOrder.SalesOrderLine[i].ItemCode);
                this.itemsName.push(this.salesOrder.SalesOrderLine[i].ItemName);
                this.itemsUom.push(this.salesOrder.SalesOrderLine[i].ItemUnit);
                this.itemsQuantityReq.push(this.salesOrder.SalesOrderLine[i].Quantity);
                this.itemsQuantityAdj.push(0);

                this.itemsPrice.push(this.salesOrder.SalesOrderLine[i].Price);
                if(this.salesOrder.SalesOrderLine[i].IsCustomPrice == 1) {
                    this.itemsPriceCustom.push(this.salesOrder.SalesOrderLine[i].Price);
                } else {
                    this.itemsPriceCustom.push(0);
                }

                this.itemsSubtotal.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscount.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTax.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotal.push(this.salesOrder.SalesOrderLine[i].Total);
                
                this.itemsSubtotalEngine.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscountEngine.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTaxEngine.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotalEngine.push(this.salesOrder.SalesOrderLine[i].Total);

                // record price model step ladder
                if (this.salesOrder.SalesOrderLine[i].Price == 0 && this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length > 0) {
                    this.itemsPrice[i] = "step-ladder";
                    var arr_temp = [];
                    for (var j = 0; j < this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length; j++) {
                        var pricingStep = this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice[j];
                        const temp = new Object();
                        temp.step = pricingStep.Step;
                        temp.qty = pricingStep.Qty;
                        temp.price = pricingStep.Price;
                        arr_temp[j] = temp;
                    }
                    this.pricingStep[i] = arr_temp;
                }

                // record discount model step ladder, unit line
                if (this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length > 0) {
                    var arr_temp_disc = [];
                    for (var k = 0; k < this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length; k++) {
                        var discountStep = this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount[k];
                        const temp = new Object();
                        temp.step = discountStep.Step;
                        temp.percentage = discountStep.Percentage;
                        temp.amount = discountStep.Amount;
                        arr_temp_disc[k] = temp;
                    }
                    this.discountStep[i] = arr_temp_disc;
                }
            }

            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                this.totalDiscountStep = this.salesOrder.SalesOrderDiscount
            }

            // record discount model step ladder, unit final
            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                for (var l = 0; l < this.salesOrder.SalesOrderDiscount.length; l++) {
                    var totalDiscountStep = this.salesOrder.SalesOrderDiscount[l];
                    const temp = new Object();
                    temp.step = totalDiscountStep.Step;
                    temp.nominal = totalDiscountStep.Nominal;
                    temp.percentage = totalDiscountStep.Percentage;
                    temp.amount = totalDiscountStep.Amount;
                    this.totalDiscountStep[l] = temp
                }
            }

            this.totalBrutto = this.formatCurrency(this.salesOrder.Subtotal); // total order = harga satuan dikali jumlah 
            this.totalDiscount = this.formatCurrency(this.salesOrder.Discount); // total diskon = totalnya diskon
            this.totalNetto = this.formatCurrency(this.salesOrder.Subtotal - this.salesOrder.Discount); // total nett = total order - total diskon
            this.totalTax = this.formatCurrency(this.salesOrder.TaxAmount); // total pajak = total nett * pajak
            this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak
            
            for (var m = 0; m < this.salesOrder.SalesOrderPromo.length; m++) {
                this.promotionCode.push(this.salesOrder.SalesOrderPromo[m].PromoCode);
            }

            // this.CalculateTotal();
            
            // SET Default value dropdown
            // Default pricing group
            if(this.salesOrder.PricingGroupID != 0){
                this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
                    return v.ID == this.salesOrder.PricingGroupID;
                });
            }

            // Default zone
            if(this.salesOrder.ZoneID != 0){
                this.zone = this.optionZones.filter((v) => {
                    return v.ID == this.salesOrder.ZoneID;
                });
            }

            // Default soType
            if(this.salesOrder.Category != ""){
                this.soType = this.optionSoType.filter((v) => {
                    return v.name == this.salesOrder.Category;
                });
            }

            // Default soType, selectDeliveryType
            if(this.salesOrder.Category != ""){
                this.selectDeliveryType = this.optionDeliveryType.filter((v) => {
                    return v.code == this.salesOrder.DeliveryType.Code;
                });
            }

            // If SO with Onetime Customer
            if (this.salesOrder.IsOneTime) {
                this.isOneTime = this.salesOrder.IsOneTime;
                // set onetime cust local data
                this.oneTimeCust.id                     = this.salesOrder.OcData.ID;
                this.oneTimeCust.customer_id            = this.salesOrder.OcData.customer_id;
                this.oneTimeCust.name                   = this.salesOrder.OcData.name;
                this.oneTimeCust.contact_person         = this.salesOrder.OcData.contact_person;
                this.oneTimeCust.nik                    = this.salesOrder.OcData.nik;
                this.oneTimeCust.phone                  = this.salesOrder.OcData.phone;
                this.oneTimeCust.payment_method_id      = this.salesOrder.OcData.payment_method_id;
                this.oneTimeCust.payment_term_id        = this.salesOrder.OcData.payment_term_id;
                
                this.oneTimeCust.address.address        = this.salesOrder.OcData.billto_address;
                this.oneTimeCust.address.postalCode     = this.salesOrder.OcData.postal_code;
                this.oneTimeCust.address.countryName    = this.salesOrder.OcData.country;
                this.oneTimeCust.address.provinceName   = this.salesOrder.OcData.province,
                this.oneTimeCust.address.cityName       = this.salesOrder.OcData.city;
                this.oneTimeCust.address.districtName   = this.salesOrder.OcData.district;
                // console.log(this.salesOrder.OcData.sub_district);
                this.oneTimeCust.address.subdistrictName= this.salesOrder.OcData.sub_district;

                // set display Customer Name, billto, shipto
                this.salesOrder.Customer.Name = this.salesOrder.OcData.name;
                
                this.salesOrder.BillAddress.Address = this.salesOrder.OcData.billto_address;
                this.salesOrder.BillAddress.City = this.salesOrder.OcData.city;
                    
                this.salesOrder.ShipAddress.Address = this.salesOrder.OcData.shipto_address;
                this.salesOrder.ShipAddress.City = this.salesOrder.OcData.city;

                // Default Sales ID onetime
                this.salesPersonal = this.optionSalesPersonal.filter((v) => {
                    return v.ID == this.salesOrder.OcData.sales_personal_id;
                });

                // Default Distribution channel onetime
                this.customerCategory = this.optionCustomerCategory.filter((v) => {
                    return v.ID == this.salesOrder.OcData.customer_category_id;
                });
                
            } else {
                // Default Sales ID
                this.salesPersonal = this.optionSalesPersonal.filter((v) => {
                    return v.ID == this.salesOrder.Customer.SalesPersonalID;
                });
                // Default Distribution channel
                this.customerCategory = this.optionCustomerCategory.filter((v) => {
                    return v.ID == this.salesOrder.Customer.CustomerCategoryID;
                });

                // Default pricing group
                if(this.salesOrder.PricingGroupID == 0){
                    this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
                        return v.ID == this.salesOrder.Customer.PricingGroupID;
                    });
                }
            }
        } else {
            this.setDefaultDate();
            this.resetSalesOrder = Object.assign({}, this.salesOrder);
        }

    },
    computed: {
        DueDate: {
            get() {
                var d = new Date()
                if(this.selectedSO && this.selectedSO.DueDate){
                    d = new Date(this.selectedSO.DueDate)
                    d.setHours(d.getHours() - 7)
                } else {
                    d = new Date(this.salesOrder.DueDate)
                }
                return d
            },
            set(val) {
                this.DueDatePost = val
                return val
            }
        },
        charactersLeft() {
            var char = this.salesOrder.Notes.length,
                limit = 255;

            return "counter " + (limit - char);
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                if(this.optionItemAvailableShow) {
                    this.getOptionItemAvailable(page);
                }
                if(this.optionCustomerShow) {
                    this.getOptionCustomer(page);
                }
                if(this.optionBillToShow) {
                    this.getOptionBillTo(page);
                }
                if(this.optionShipToShow) {
                    this.getOptionShipTo(page);
                }
                return page;
            }
        },
    },
    watch: {
        search: function() {
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable();
            }
            if(this.optionCustomerShow) {
                this.getOptionCustomer();
            }
            if(this.optionBillToShow) {
                this.getOptionBillTo();
            }
            if(this.optionShipToShow) {
                this.getOptionShipTo();
            }
        },
        itemsQuantityReq: function() {
            this.calculated = false;
        },
        itemsQuantityAdj: function() {
            this.calculated = false;
        },
        selectTax: function() {
            this.oneTimeCust.tax_id = this.selectTax.ID;
            this.calculated = false;
        },
        selectDeliveryType: function(newValue, oldValue){
            if(newValue.parcel_services == 1) {
                this.deliveryOrderCodeIsDisabled = false;
            } else {
                this.deliveryOrderCodeIsDisabled = true;
                if(this.selectedSO && this.selectedSO.Code) {
                    this.salesOrder.DeliveryOrderCode = this.selectedSO.DeliveryOrderCode;
                } else {
                    this.salesOrder.DeliveryOrderCode = "";
                }
            }
        },
        territory: function () {
            if(!this.isOneTime){
                if(this.optionItemAvailableShow) {
                    this.getOptionItemAvailable();
                }
                if(this.optionCustomerShow) {
                    this.getOptionCustomer();
                }
                if(this.optionBillToShow) {
                    this.getOptionBillTo();
                }
                if(this.optionShipToShow) {
                    this.getOptionShipTo();
                }
            }
        },
        DueDate: function() {
            let today = new Date()
            if(this.salesOrder.DueDate < today) {
                    this.$vs.notify({
                        title: "Warning",
                        text: "Delivery date canot backdated",
                        color: "warning",
                        position: "top-right",
                        icon: "error"
                    });
            }
            if(this.salesOrder.DueDate < this.salesOrder.soDate) {
                    this.$vs.notify({
                        title: "Warning",
                        text: "Delivery date must greater than SO date",
                        color: "warning",
                        position: "top-right",
                        icon: "error"
                    });
            }
        },
        isOneTime: function() {
            this.handleClose();
            this.calculated = false;
            if(!this.isOneTime){
                // reset shipto
                this.salesOrder.ShipAddress.Address = "";
                this.salesOrder.ShipAddress.City = "";
                this.salesOrder.ShipmentToAddressID = 0;
                // reset billto
                this.salesOrder.BillAddress.Address = "";
                this.salesOrder.BillAddress.City = "";
                this.salesOrder.BillToAddressID = 0;
                // reset custData
                this.salesOrder.Customer.Name = "";
                this.salesOrder.Customer.Code = "";
                this.salesOrder.Customer.ID = 0;
            }else{
                // Get onetimeCust master data
                if(!this.selectedSO.IsOneTime){
                    this.showOTCForm = true;
                    this.getOnetimeCustomer();
                }
            }
        },
    },
    data: () => ({
        configFlatPickrDueDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y H:i',
            enableTime: true,
            minTime: "00:00",
            plugins: [new ConfirmDatePlugin()]
        },

        configFlatPickrDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y',
            enableTime: false,
        },

        hour: "",
        minute: "",

        update: false,
        calculated: false,
        external: false,
        deliveryOrderCodeIsDisabled: true,

        selectTax:{
            Name: "",
            Rate: 0
        },
        selectPaymentTerm:{},
        selectDeliveryType:{},

        resetSalesOrder: {},
        salesOrder: {
            Code: "",
            PoReferenceCode: "",
            DeliveryOrderCode: "",
            ReservationCode: "",
            Customer: {
                ID: 0,
                Name: "",
                Code: "",
                Type: "",
                PaymentMethodID: "",
            },
            Territorry: {
                Code: ""
            },
            Company: {
                Code: ""
            },
            SalesChannel: {
                Code: "",
                PriceProposal: "Disallow",
            },
            BillToAddressID: 0,
            BillAddress: {
                Address: "",
                City: ""
            },
            ShipmentToAddressID: 0,
            ShipAddress: {
                Address: "",
                City: ""
            },
            soDate: "",
            DueDate: "",
            AllowPartial: 0,
            Notes: "",
            PaymentTermID: 0,
            SubtotalCharge: 0,
        },
        DueDatePost: "",
        
        priceProposal: "Disallow",

        itemsCode: [],
        itemsName: [],
        itemsUom: [],
        itemsQuantityReq: [],
        itemsQuantityAdj: [],

        itemsPrice: [],
        itemsPriceCustom: [],
        itemsSubtotal: [],
        itemsDiscount: [],
        itemsTax: [],
        itemsLineTotal: [],

        // for konversi HU
        itemsLineHU: [], // [Pcs, Lusin, Karton, dst]
        itemsLineQty: [], // ["5.3.1", "1.2.1"] => "5pcs.3lusin.1karton", "1pcs.2lusin.1karton" dst
        itemsLineQtyConv: [], // konversi HU ke UOM qty. Misal data diatas 5.3.1 , 1.2.1 ==> [ 5+36+20, 1+24+20 ] ==> [61, 45]

        // SO Line Items
        salesOrderLines: [
            /*  
                Example
                {
                    sku_code: item.sku_code,
                    sku_name: item.item_name,
                    hu_labels: resp.data,
                    qty_hu: [],
                    items_hu: sumHU.join('.'),
                    items_qty: 0,
                    list_price: [],
                    reg_disc: [],
                    promotion: [],
                    tax: [],
                    subtotal: {
                        price: [],
                        all_price: 0,
                        disc: 0,
                        tax: 0,
                    },
                }
            */
        ],
        
        itemsSubtotalEngine: [],
        itemsDiscountEngine: [],
        itemsTaxEngine: [],
        itemsLineTotalEngine: [],

        pricingStep: [],
        discountStep: [],
        totalDiscountStep: [],
        
        totalBrutto: 0,
        totalDiscount: 0,
        totalNetto: 0,
        totalTax: 0,
        totalBilling: 0,
        promotionCode:[],
        limitedItem:[],

        optionCustomer: [{}],
        optionItemAvailable: [{}],
        optionBillTo: [{}],
        optionShipTo: [{}],
        optionTax: [{}],
        optionPaymentTerm: [{}],
        optionDeliveryType: [{}],
        optionSoType: [
            {'name':'COD', 'desc':'Cash Order', 'code':'ZDCO'},
            {'name':'TOP', 'desc':'Regular Order', 'code':'ZDTO'},
            {'name':'B2B', 'desc':'Back to back Order', 'code':'ZDBB'},
            {'name':'CVS', 'desc':'Canvas Order', 'code':'ZDCV'},
            {'name':'CBD', 'desc':'Cash Before Delivery', 'code':''},
            {'name':'SMP', 'desc':'Sample', 'code':''},
            {'name':'OTH', 'desc':'Other', 'code':''},
        ],
        soType: null,
        salesID: null,
        optionSalesman: [],
        salesPersonal: null,
        optionSalesPersonal:[{}],

        optionItemAvailableShow: false,
        optionCustomerShow: false,
        optionBillToShow: false,
        optionShipToShow: false,
        optionPromotionCodeShow:false,
        optionReservationCodeShow: false,
        detailStepShow: false,
        detailTotalDiscountStepShow: false,
        showOTCForm: false,

        indexStep: 0,

        PriceModel: '',
        DiscountModel: '',
        DiscountUnit: '',

        limits: [10],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
        territory: {},
        optionTerritory: [{}],
        zone: null,
        optionZones: [{}],
        salesChannel: {},
        optionSalesChannel:[{}],
        customerCategory: {},
        optionCustomerCategory:[{}],
        priceGroupID:null,
        loading: {
            salesman: false,
        },
        counterLoading: {
            salesman: 0,
        },
        isOneTime: false,
        sameAddress: false,
        oneTimeCust: {
            id:null,
            customer_id:null,
            name:null,
            address: {
                address: "",
                postalCode: "",
                countryName: "",
                provinceName: "",
                cityName: "",
                districtName: "",
                subdistrictName: "",
            },
            contact_person:null,
            nik:null,
            phone:null,
            payment_method_id:null,
            tax_id:null,
        },
        pricingGroup: {
          selected: null,
          options: [],
        },
    }),
    methods: {
        async getAllDataPricingGroup() {
            this.$vs.loading();
            await this.$http.get("/api/v1/master/pricing-group", {
                params: {
                    order: "name",
                    sort: "asc",
                },
            }).then((resp) => {
                if (resp.status == "success") {
                    this.pricingGroup.options = resp.data.records;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            });
        },
        async getOptionZones() {
            this.optionZones = [];
            this.$vs.loading();
            await this.$http.get("/api/v1/master/zone").then((resp) => {
                if (resp.status == "success") {
                        this.optionZones = resp.data.records;
                        this.$vs.loading.close();
                } else {
                        this.$vs.loading.close();
                }
            });
        },
        checkDate() {
            let soDate = this.salesOrder.soDate
            let deliveryDate = this.salesOrder.DueDate

            if(soDate.getFullYear() > deliveryDate.getFullYear()) {
                console.log(soDate.getFullYear(), deliveryDate.getFullYear())
                return false;
            }

            if(soDate.getMonth() > deliveryDate.getMonth()) {
                console.log(soDate.getMonth(), deliveryDate.getMonth())
                return false;
            }

            if(soDate.getMonth() == deliveryDate.getMonth() && soDate.getDate() > deliveryDate.getDate()) {
                console.log(soDate.getDate(), deliveryDate.getDate())
                return false;
            }

            return true
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        ResetForm() {
            this.calculated = false;
            this.salesOrder = Object.assign({}, this.resetSalesOrder);

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];
            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];
            
            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];

            this.pricingStep = [];
            this.discountStep = [];
            this.totalDiscountStep = [];

            this.totalBrutto = 0;
            this.totalDiscount = 0;
            this.totalNetto = 0;
            this.totalTax = 0;
            this.totalBilling = 0;

            this.promotionCode = [],
            this.limitedItem = [],

            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;

            this.indexStep = 0;

            if(this.selectedSO.SalesChannel.PriceProposal == "Allow") {
                this.priceProposal = "Allow";
            } else {
                this.priceProposal = "Disallow";
            }

            for (var i = 0; i < this.salesOrder.SalesOrderLine.length; i++) {
                this.itemsCode.push(this.salesOrder.SalesOrderLine[i].ItemCode);
                this.itemsName.push(this.salesOrder.SalesOrderLine[i].ItemName);
                this.itemsUom.push(this.salesOrder.SalesOrderLine[i].ItemUnit);
                this.itemsQuantityReq.push(this.salesOrder.SalesOrderLine[i].Quantity);
                this.itemsQuantityAdj.push(0);

                this.itemsPrice.push(this.salesOrder.SalesOrderLine[i].Price);
                if(this.salesOrder.SalesOrderLine[i].IsCustomPrice == 1) {
                    this.itemsPriceCustom.push(this.salesOrder.SalesOrderLine[i].Price);
                } else {
                    this.itemsPriceCustom.push(0);
                }

                this.itemsSubtotal.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscount.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTax.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotal.push(this.salesOrder.SalesOrderLine[i].Total);

                this.itemsSubtotalEngine.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscountEngine.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTaxEngine.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotalEngine.push(this.salesOrder.SalesOrderLine[i].Total);

                // record price model step ladder
                if (this.salesOrder.SalesOrderLine[i].Price == 0 && this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length > 0) {
                    this.itemsPrice[i] = "step-ladder";
                    var arr_temp = [];
                    for (var j = 0; j < this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length; j++) {
                        var pricingStep = this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice[j];
                        const temp = new Object();
                        temp.step = pricingStep.Step;
                        temp.qty = pricingStep.Qty;
                        temp.price = pricingStep.Price;
                        arr_temp[j] = temp;
                    }
                    this.pricingStep[i] = arr_temp;
                }

                // record discount model step ladder, unit line
                if (this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length > 0) {
                    var arr_temp_disc = [];
                    for (var k = 0; k < this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length; k++) {
                        var discountStep = this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount[k];
                        const temp = new Object();
                        temp.step = discountStep.Step;
                        temp.percentage = discountStep.Percentage;
                        temp.amount = discountStep.Amount;
                        arr_temp_disc[k] = temp;
                    }
                    this.discountStep[i] = arr_temp_disc;
                }
            }

            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                this.totalDiscountStep = this.salesOrder.SalesOrderDiscount
            }

            // record discount model step ladder, unit final
            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                for (var l = 0; l < this.salesOrder.SalesOrderDiscount.length; l++) {
                    var totalDiscountStep = this.salesOrder.SalesOrderDiscount[l];
                    const temp = new Object();
                    temp.step = totalDiscountStep.Step;
                    temp.nominal = totalDiscountStep.Nominal;
                    temp.percentage = totalDiscountStep.Percentage;
                    temp.amount = totalDiscountStep.Amount;
                    this.totalDiscountStep[l] = temp
                }
            }

            this.totalBrutto = this.formatCurrency(this.salesOrder.Subtotal); // total order = harga satuan dikali jumlah 
            this.totalDiscount = this.formatCurrency(this.salesOrder.Discount); // total diskon = totalnya diskon
            this.totalNetto = this.formatCurrency(this.salesOrder.Subtotal - this.salesOrder.Discount); // total nett = total order - total diskon
            this.totalTax = this.formatCurrency(this.salesOrder.TaxAmount); // total pajak = total nett * pajak
            this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak
            
            for (var m = 0; m < this.salesOrder.SalesOrderPromo.length; m++) {
                this.promotionCode.push(this.salesOrder.SalesOrderPromo[m].PromoCode);
            }
        },
        CancelForm() {
            this.resetSalesOrder = {
                Code: "",
                PoReferenceCode: "",
                DeliveryOrderCode: "",
                Customer: {
                    ID: 0,
                    Name: "",
                    Code: "",
                },
                Territorry: {
                    Code: ""
                },
                Company: {
                    Code: ""
                },
                SalesChannel: {
                    Code: "",
                    PriceProposal: "Disallow",
                },
                BillToAddressID: 0,
                BillAddress: {
                    Address: "",
                    City: ""
                },
                ShipmentToAddressID: 0,
                ShipAddress: {
                    Address: "",
                    City: ""
                },
                Date: "",
                DueDate: "",
                AllowPartial: 1,
                Notes: "",
                PaymentTermID: 0,
                SubtotalCharge: 0,
            };
            this.salesOrder = Object.assign({}, this.resetSalesOrder);
            this.selectTax = {
                Name: "",
                Rate: 0
            };
            this.selectPaymentTerm = {};
            this.selectDeliveryType = {};
            this.calculated = false;
            this.update = false;
            this.external = false;
            this.deliveryOrderCodeIsDisabled = true;
            this.priceProposal = "Disallow";

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];
            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];
            
            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];

            this.pricingStep = [];
            this.discountStep = [];
            this.totalDiscountStep = [];

            this.totalBrutto = 0;
            this.totalDiscount = 0;
            this.totalNetto = 0;
            this.totalTax = 0;
            this.totalBilling = 0;

            this.promotionCode = [],
            this.limitedItem = [],

            this.salesOrderLines = [],

            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;

            this.indexStep = 0;

            this.PriceModel = '';
            this.DiscountModel = '';
            this.DiscountUnit = '';

            // new params/var reset
            this.territory = null;
            this.isOneTime = false;
            this.oneTimeCust = {
                id:null,
                customer_id:null,
                name:null,
                address: {
                    address: "",
                    postalCode: "",
                    countryName: "",
                    provinceName: "",
                    cityName: "",
                    districtName: "",
                    subdistrictName: "",
                },
                contact_person:null,
                nik:null,
                phone:null,
                payment_method_id:null,
                tax_id:null,
            };
            this.customerCategory = {};
            this.pricingGroup.selected = null;
            this.zone = null;
            this.soType = null;
            this.salesPersonal = null;

            this.$emit("selectSalesOrder", {});
        },
        browseItem() {
            this.search = "";
            this.indexStep = 0;
            this.optionItemAvailableShow = true;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.getOptionItemAvailable();
        },
        browseCustomer() {
            this.search = "";
            this.indexStep = 0;
            this.optionCustomerShow = true;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            if(!this.isOneTime){
                this.getOptionCustomer();
            }
        },
        browseBillTo() {
            this.search = "";
            this.indexStep = 0;
            this.optionBillToShow = true;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            if(!this.isOneTime){
                this.getOptionBillTo();
            }
        },
        browseShipTo() {
            this.search = "";
            this.indexStep = 0;
            this.optionShipToShow = true;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            if(!this.isOneTime){
                this.getOptionShipTo();
            }
        },
        detailStep(index) {
            this.search = "";
            this.indexStep = index;
            this.detailStepShow = true;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
        },
        browsePromotionCode() {
            this.search = "";
            this.indexStep = 0;
            this.optionPromotionCodeShow = true;
            this.detailStepShow = false;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionReservationCodeShow = false;
        },
        browseReservationCode() {
            this.search = "";
            this.indexStep = 0;
            this.optionReservationCodeShow = true;
            this.detailStepShow = false;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.optionPromotionCodeShow = false;
            this.detailTotalDiscountStepShow = false;
        },
        detailTotalDiscountStep() {
            this.search = "";
            this.indexStep = 0;
            this.detailTotalDiscountStepShow = true;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailStepShow = false;
        },
        addItemLine(item) {
            this.calculated = false;
            if(this.external == false) {
                let duplicate = false;
                this.salesOrderLines.forEach(v => {
                    if(v.sku_code == item.sku_code){
                        duplicate = true;
                    }
                });
                if(duplicate) {
                    this.$vs.notify({
                        title: "Warning",
                        text: "Duplicate item detected ",
                        color: "warning",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                } else {
                    // HIT EP Disini untuk get Amount UOM dan HU items
                    this.$vs.loading();
                    this.$http.get("/api/v1/item-hu", {
                        params: {
                            sku_code: item.sku_code,
                        },
                    }).then((resp) => {
                        console.log(resp)
                        if (resp.status == "success") {
                            // 
                            // ======= Add items dan HU =======
                            // salesOrderLines
                            let sumHU = [];
                            for (let i = 0; i < resp.data.length; i++) {
                                sumHU.push(0);
                            }

                            let salesOrderLines = {
                                sku_code: item.sku_code,
                                sku_name: item.item_name,
                                hu_labels: resp.data,
                                qty_hu: [],
                                items_hu: sumHU.join('.'),
                                items_qty: 0,
                                list_price: [],
                                reg_disc: [],
                                promotion: [],
                                tax: [],
                                subtotal: {
                                    price: [],
                                    all_price: 0,
                                    disc: 0,
                                    tax: 0,
                                },
                            }
                            
                            this.salesOrderLines.push(salesOrderLines);

                            this.$vs.notify({
                                title: "Added",
                                text: item.item_name,
                                color: "primary",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check"
                            });
                            this.$vs.loading.close();
                        } else {
                            console.log(resp.message)
                            this.$vs.loading.close();
                        }
                    });
                }
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Sales Order item line ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        selectCustomer(customer) {
            this.calculated = false;
            this.updateSelectedPromotionCode([]);
            this.updateLimitItemByPromotion([]);
            if(this.external == false) {
                // hanya update value yg penting2 aja wkwk
                this.salesOrder.Customer.Code = customer.code;
                this.salesOrder.Customer.Name = customer.name;
                this.salesOrder.Customer.ID = customer.id;

                this.getOptionBillTo(1, true);
                // this.salesOrder.BillToAddressID = null;
                // this.salesOrder.BillAddress.Address = null;
                // this.salesOrder.BillAddress.City = null;

                this.getOptionShipTo(1, true);
                // this.salesOrder.ShipmentToAddressID = null;
                // this.salesOrder.ShipAddress.Address = null;
                // this.salesOrder.ShipAddress.City = null;
                
                // this.getTime(this.salesOrder.CustomerID); // ini utk set default time based on custsomer work time

                // set default payment term
                this.selectPaymentTerm = this.optionPaymentTerm.filter((v) => {
                    return v.name == customer.payment_term_name;
                });

                this.selectPaymentTerm = this.selectPaymentTerm[0]

                // Default Distribution channel
                this.customerCategory = this.optionCustomerCategory.filter((v) => {
                    return v.name == customer.customer_category_name;
                });

                // Default Pricing Group
                this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
                    return v.ID == customer.pricing_group_id;
                });

                // Default Sales ID
                this.salesPersonal = this.optionSalesPersonal.filter((v) => {
                    return v.ID == customer.sales_personal_id;
                });

                this.handleClose();
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to change external Sales Order Customer ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        selectBillTo(billTo) {
            if(this.external == false) {
                // hanya update value yg penting2 aja wkwk
                this.salesOrder.BillAddress.Address = billTo.address;
                this.salesOrder.BillAddress.City = billTo.city;
                this.salesOrder.BillToAddressID = billTo.id;
                this.handleClose();
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to change external Sales Order Customer ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        selectShipTo(shipTo) {
            if(this.external == false) {
                // hanya update value yg penting2 aja wkwk
                this.salesOrder.ShipAddress.Address = shipTo.address;
                this.salesOrder.ShipAddress.City = shipTo.city;
                this.salesOrder.ShipmentToAddressID = shipTo.id;

                this.territory = this.optionTerritory.filter((v) => {
                    return v.id == shipTo.territory_id;
                });

                this.handleClose();
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to change external Sales Order Customer ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        updateSelectedPromotionCode(selectedPromotionCode) {
            this.calculated = false;
            this.promotionCode = selectedPromotionCode;
            console.log("updateSelectedPromotionCode", this.promotionCode);
            this.handleClose();
        },
        updateLimitItemByPromotion(limitedItem) {
            this.calculated = false;

            for (let index = 0; index < limitedItem.length; index++) {
                this.limitedItem[index] = limitedItem[index].item_unit_id;
            }
            console.log("updateLimitItemByPromotion", this.limitedItem);

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];
            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];
            
            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];

            this.pricingStep = [];
            this.discountStep = [];
            this.totalDiscountStep = [];

            this.totalBrutto = 0;
            this.totalDiscount = 0;
            this.totalNetto = 0;
            this.totalTax = 0;
            this.totalBilling = 0;
        },
        updateSelectedReservationCode(selectedReservationCode) {
            this.calculated = false;
            this.salesOrder.ReservationCode = selectedReservationCode;
            console.log("updateSelectedReservationCode", this.salesOrder.ReservationCode);
        },
        deleteItemLine(index) {
            this.calculated = false;
            if(this.external == false) {
                this.$vs.notify({
                    title: "Deleted",
                    text: this.itemsName[index],
                    color: "primary",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });

                this.salesOrderLines.splice(index, 1);
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Sales Order item line",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        changeLimit(limit) {
            this.limitShow = limit;
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable(this.isActive);
            }
            if(this.optionCustomerShow) {
                this.getOptionCustomer(this.isActive);
            }
            if(this.optionBillToShow) {
                this.getOptionBillTo(this.isActive);
            }
            if(this.optionShipToShow) {
                this.getOptionShipTo(this.isActive);
            }
        },
        changePrice() {
            this.calculated = false;
        },
        changeCharge() {            
            this.calculated = false;
        },
        getOptionItemAvailable(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/get-item-available", {
                params: {
                    // territory_code : this.territory.code,
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1),
                    item_unit_id: this.limitedItem
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionItemAvailable = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Item Available",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionCustomer(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                    type: "End Customer",
                    // territory_id: this.territory.id,
                    zone_restriction: true,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                if (resp.code == 200) {
                    this.optionCustomer = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Customer",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            });
        },
        // customer-onetime
        getOnetimeCustomer() {
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer-onetime").then(resp => {
                // console.log("OT customer", resp);
                if (resp.code == 200) {
                    this.salesOrder.Customer.ID = resp.data.records.ID;
                    this.salesOrder.Customer.Code = resp.data.records.code;
                    this.salesOrder.Customer.Name = "";
                    this.salesOrder.Customer.Type = resp.data.records.customer_type;
                    this.salesOrder.Customer.PaymentMethodID = resp.data.records.payment_method_id;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Customer",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionBillTo(page = 1, autoDefault = false) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer-address/bill-to", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                    customer_id: this.salesOrder.Customer.ID,
                    // territory_id: this.territory.id,
                }
            }).then(resp => {
                // console.log("option customer billto", resp);
                if (resp.code == 200) {
                    this.optionBillTo = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;

                    if(autoDefault == true && this.optionBillTo.length > 0) {
                        this.salesOrder.BillAddress.Address = this.optionBillTo[0].address;
                        this.salesOrder.BillAddress.City = this.optionBillTo[0].city;
                        this.salesOrder.BillToAddressID = this.optionBillTo[0].ID;
                    }

                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Bill To Address",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionShipTo(page = 1, autoDefault = false) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer-address/ship-to", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                    customer_id: this.salesOrder.Customer.ID,
                    // territory_id: this.territory.id,
                }
            }).then(resp => {
                // console.log("option customer shipto", resp);
                if (resp.code == 200) {
                    this.optionShipTo = resp.data.shipTo;
                    // this.recordsTotal = resp.data.total_record;
                    // this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    // this.dataEnd = resp.data.total_record_search;

                    if(autoDefault == true && this.optionShipTo.length > 0) {
                        this.salesOrder.ShipAddress.Address = this.optionShipTo[0].address;
                        this.salesOrder.ShipAddress.City = this.optionShipTo[0].city;
                        this.salesOrder.ShipmentToAddressID = this.optionShipTo[0].ID;

                        // set territory in segmentation data
                        this.territory = this.optionTerritory.filter((v) => {
                            return v.id == this.optionShipTo[0].territory_id;
                        });

                        this.territory = this.territory[0];

                        // Default zone
                        this.zone = this.optionZones.filter((v) => {
                            return v.ID == this.optionShipTo[0].zone_id;
                        });

                        this.zone = this.zone[0];
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Ship To Address",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            });
        },
        async getOptionTax(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            await this.$http.get("/api/v1/master/tax").then(resp => {
                if (resp.code == 200) {
                    this.optionTax = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;

                    for (let index = 0; index < this.optionTax.length; index++) {
                        if(this.optionTax[index].IsDefault == 1 && this.selectTax.Name == "") {
                            this.selectTax = {
                                Name: this.optionTax[index].Name,
                                Rate: this.optionTax[index].Rate
                            };
                            break;
                        }   
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Tax option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                this.$vs.loading.close();
            });
        },
        async getOptionPaymentTerm() {
            this.$vs.loading();

            await this.$http.get("/api/v1/master/payment-term", {
                params: {
                    order: 'name',
                    sort: 'asc',
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionPaymentTerm = resp.data.records;
                    if(this.salesOrder.PaymentTermID == 0 || this.salesOrder.PaymentTermID == null) {
                        this.selectPaymentTerm = {
                            id: this.optionPaymentTerm[0].id,
                            name: this.optionPaymentTerm[0].name,
                            method_name: this.optionPaymentTerm[0].payment_method_name
                        };
                    } else {
                        for (let index = 0; index < this.optionPaymentTerm.length; index++) {
                            if(this.optionPaymentTerm[index].id == this.salesOrder.PaymentTermID) {
                                this.selectPaymentTerm = {
                                    id: this.optionPaymentTerm[index].id,
                                    name: this.optionPaymentTerm[index].name,
                                    method_name: this.optionPaymentTerm[index].payment_method_name
                                };
                                break;
                            }   
                        }
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Payment Term option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        async getOptionDeliveryType() {
            this.$vs.loading();

            await this.$http.get("/api/v1/master/delivery-type", {
                params: {
                    order: 'id',
                    sort: 'asc',
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionDeliveryType = resp.data.records;
                    if(this.salesOrder.DeliveryTypeID == 0 || this.salesOrder.DeliveryTypeID == null) {
                        this.selectDeliveryType = {
                            id: this.optionDeliveryType[0].id,
                            name: this.optionDeliveryType[0].name,
                            own_delivery: this.optionDeliveryType[0].own_delivery,
                            pickup: this.optionDeliveryType[0].pickup,
                            parcel_services: this.optionDeliveryType[0].parcel_services,
                            code: this.optionDeliveryType[0].code
                        };
                    } else {
                        for (let index = 0; index < this.optionDeliveryType.length; index++) {
                            if(this.optionDeliveryType[index].id == this.salesOrder.DeliveryTypeID) {
                                this.selectDeliveryType = {
                                    id: this.optionDeliveryType[index].id,
                                    name: this.optionDeliveryType[index].name,
                                    own_delivery: this.optionDeliveryType[index].own_delivery,
                                    pickup: this.optionDeliveryType[index].pickup,
                                    parcel_services: this.optionDeliveryType[index].parcel_services,
                                    code: this.optionDeliveryType[index].code
                                };
                                break;
                            }   
                        }
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Delivery Type option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        CalculateSOLine() {
            // console.log(this.selectPaymentTerm)
            if(this.salesOrder.Customer.Code == "" || this.salesOrder.Customer.Code == 0 || this.salesOrder.Customer.Code == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Customer first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if((this.salesOrder.BillToAddressID == "" || this.salesOrder.BillToAddressID == 0 || this.salesOrder.BillToAddressID == null ) && this.oneTimeCust.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Billing Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if((this.salesOrder.ShipmentToAddressID == "" || this.salesOrder.ShipmentToAddressID == 0 || this.salesOrder.ShipmentToAddressID == null ) && this.oneTimeCust.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Shipping Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(this.salesOrder.soDate == "" || this.salesOrder.soDate == 0 || this.salesOrder.soDate == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(this.salesOrder.DueDate == "" || this.salesOrder.DueDate == 0 || this.salesOrder.DueDate == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date must be equal or after Sales Order Date",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(this.selectPaymentTerm.id == "" || this.selectPaymentTerm.id == 0 || this.selectPaymentTerm.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Payment Term first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(this.selectDeliveryType.id == "" || this.selectDeliveryType.id == 0 || this.selectDeliveryType.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Type first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            this.search = "";
            this.indexStep = 0;
            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;

            // Old params
            let params = {
                sales_channel_code: this.salesOrder.SalesChannel.Code,
                territory_code: this.territory.code,
                company_code: this.salesOrder.Company.Code,
                customer_code: this.salesOrder.Customer.Code,
                promo_code: this.promotionCode,
                // items_code: this.itemsCode,
                // items_name: this.itemsName,
                // items_uom: this.itemsUom,
                // items_quantity_request: this.itemsQuantityReq,
                // items_quantity_adjusted: this.itemsQuantityAdj,
                // items_price: this.itemsPriceCustom,
                tax_rate: this.selectTax.Rate,
                subtotal_charge: parseFloat(this.salesOrder.SubtotalCharge),
            };

            // console.log('old params', params)
            // console.log('this.territory', this.territory)
            // console.log('this.salesOrderLines', this.salesOrderLines)
            // new params (single line SO line, QTY per HU dikonversi ke uom saat diinput jumlahnya)
            // perlu diproses dulu
            params.items_code = [];
            params.items_name = [];
            params.items_uom = [];
            params.items_quantity_request = [];
            params.items_quantity_adjusted = [];
            params.items_price = [];
            for (let i = 0; i < this.salesOrderLines.length; i++) {
                let qty_per_HU = this.salesOrderLines[i].items_hu.split('.');
                for (let j = 0; j < this.salesOrderLines[i].hu_labels.length; j++) {
                    params.items_code.push(this.salesOrderLines[i].sku_code);
                    params.items_name.push(this.salesOrderLines[i].sku_name);
                    params.items_uom.push(this.salesOrderLines[i].hu_labels[j].unit);
                    params.items_quantity_request.push(0);
                    params.items_quantity_adjusted.push(parseInt(qty_per_HU[j]));
                    params.items_price.push(0);
                }
            }

            console.log('NEW params',params)
            
            this.$vs.loading();
            this.$http
            .post("/api/v1/sales-order/calculate", params)
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    
                    this.itemsPrice = [];
                    this.itemsPriceCustom = [];
                    this.itemsSubtotal = [];
                    this.itemsTax = [];
                    this.itemsLineTotal = [];
                    
                    this.itemsSubtotalEngine = [];
                    this.itemsTaxEngine = [];
                    this.itemsLineTotalEngine = [];

                    this.pricingStep = [];
                    this.discountStep = [];
                    this.totalDiscountStep = [];
                    
                    this.discountStepEngine = [];
                    this.totalDiscountStepEngine = [];

                    var sales_order = resp.data;
                    this.totalBrutto = this.formatCurrency(sales_order.total_order); // total order = harga satuan dikali jumlah 
                    this.totalDiscount = this.formatCurrency(sales_order.total_discount); // total diskon = totalnya diskon
                    this.totalNetto = this.formatCurrency(sales_order.total_netto); // total nett = total order - total diskon
                    this.totalTax = this.formatCurrency(sales_order.total_tax); // total pajak = total nett * pajak
                    this.totalBilling = this.formatCurrency(sales_order.total_billing); // total bayar = total nett + total pajak
                    this.salesOrder.SubtotalCharge = sales_order.total_charge

                    this.priceProposal = sales_order.price_proposal;

                    this.calculated = true;
                    for (var i = 0; i < sales_order.pricing.length; i++) {
                        this.itemsPrice[i] = sales_order.pricing[i].price;
                        this.itemsSubtotal[i] = sales_order.pricing[i].sub_total;
                        this.itemsTax[i] = sales_order.pricing[i].tax;

                        this.itemsSubtotalEngine[i] = sales_order.pricing[i].sub_total_engine;
                        this.itemsTaxEngine[i] = sales_order.pricing[i].tax_engine;

                        if(sales_order.pricing[i].sub_total <= 0 && sales_order.pricing[i].sub_total_engine <= 0) {
                            alert("ada yang 0")
                            this.calculated = false;
                        }
                        
                        // PRICING
                        if(sales_order.price_model == "Step Ladder" && sales_order.pricing[i].steps.length > 0) {
                            this.pricingStep[i] = sales_order.pricing[i].steps;
                        } else {
                            this.pricingStep = [];
                        }
                        this.itemsPriceCustom[i] = sales_order.pricing[i].custom_price;

                        // DISCOUNT
                        var lineTotal = 0;
                        var lineTotalEngine = 0;
                        if(i < sales_order.discount_line.length) {
                            if(sales_order.discount_model == "Step Ladder" && this.containsKey(sales_order.discount_line[i], 'steps') && sales_order.discount_line[i].steps.length > 0) {
                                this.discountStep[i] = sales_order.discount_line[i].steps;
                                this.discountStepEngine[i] = sales_order.discount_line_engine[i].steps;
                            } else {
                                this.discountStep = [];
                                this.discountStepEngine = [];
                            }
                            this.itemsDiscount[i] = sales_order.discount_line[i].amount;
                            this.itemsDiscountEngine[i] = sales_order.discount_line_engine[i].amount;

                            lineTotal = sales_order.pricing[i].sub_total - sales_order.discount_line[i].amount + sales_order.pricing[i].tax;
                            lineTotalEngine = sales_order.pricing[i].sub_total_engine - sales_order.discount_line_engine[i].amount + sales_order.pricing[i].tax_engine;
                        } else {
                            this.discountStep = [];
                            this.discountStepEngine = [];
                            this.itemsDiscount[i] = 0;
                            this.itemsDiscountEngine[i] = 0;
                            lineTotal = sales_order.pricing[i].sub_total - 0 + sales_order.pricing[i].tax;
                            lineTotalEngine = sales_order.pricing[i].sub_total_engine - 0 + sales_order.pricing[i].tax_engine;
                        }

                        this.itemsLineTotal[i] = lineTotal;
                        this.itemsLineTotalEngine[i] = lineTotalEngine;
                    }

                    // Loops price/pricing per SO Line, per HU
                    let cout = 0;
                    for (let i = 0; i < this.salesOrderLines.length; i++) {
                        this.salesOrderLines[i].list_price = [];
                        this.salesOrderLines[i].reg_disc = [];
                        this.salesOrderLines[i].tax = [];
                        this.salesOrderLines[i].qty_hu = [];
                        this.salesOrderLines[i].subtotal.price = [];

                        /* 
                        Jika items tidak ada price nya
                        Note mas Galang
                            ini untuk handle jika harga item belum diset di master
                            karena jika belum diset (price = 0) & sales bisa custom price
                            memungkinkan user utk malas set master harga & jika custom price, SO akan masuk ke manager
                            daripada manual input harga dg fitur custom price berulang kali (dg harga yg sama), sebaiknya setting aja di master
                            dengan begitu mengurangi jumlah SO yg masuk ke manager karena ada harga yg menggunakan custom price
                        */ 

                        if(sales_order.pricing[ cout ].price <= 0) {
                            this.$vs.notify({
                                title: "Error",
                                text: "Please setting price for item : "+this.salesOrderLines[i].sku_name,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                            this.calculated = false;
                        }

                        let st_tax = 0, st_price = 0, st_disc = 0;
                        for (let j = 0; j < this.salesOrderLines[i].hu_labels.length; j++) {
                            // sales_order.pricing[i]
                            this.salesOrderLines[i].list_price.push( sales_order.pricing[ cout ].price );
                            this.salesOrderLines[i].reg_disc.push( sales_order.pricing[ cout ].discountable );
                            this.salesOrderLines[i].tax.push( sales_order.pricing[ cout ].tax );
                            this.salesOrderLines[i].qty_hu.push( sales_order.pricing[ cout ].qty );
                            // Promotion nya belum
                            
                            // subtotals 
                            st_tax += sales_order.pricing[ cout ].tax;
                            st_price += sales_order.pricing[ cout ].sub_total;
                            st_disc += sales_order.pricing[ cout ].sub_total_discountable;

                            // subtotal itemline per HU
                            this.salesOrderLines[i].subtotal.price.push( sales_order.pricing[ cout ].sub_total );
                            cout++;
                        }

                        this.salesOrderLines[i].subtotal.disc = st_disc;
                        this.salesOrderLines[i].subtotal.all_price = st_price;
                        this.salesOrderLines[i].subtotal.tax = st_tax;
                    }
                    
                    // discount unit == final
                    if(sales_order.discount_unit == "Total" && sales_order.discount_model == "Step Ladder" && this.containsKey(sales_order.discount, 'steps') && sales_order.discount.steps.length > 0) {
                        this.totalDiscountStep = sales_order.discount.steps;
                        this.totalDiscountStepEngine = sales_order.discount_engine.steps;
                    }

                    this.PriceModel = sales_order.price_model;
                    this.DiscountModel = sales_order.discount_model;
                    this.DiscountUnit = sales_order.discount_unit;

                    if(sales_order.total_billing <= 0) {
                        alert("ada yang 0")
                        this.calculated = false;
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to calculate Sales Order",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                    this.calculated = false;
                }
                if(this.calculated==true) {
                    this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        async SubmitForm() {
            // console.log("submit", this.salesOrder);
            if((this.salesOrder.BillToAddressID == "" || this.salesOrder.BillToAddressID == 0 || this.salesOrder.BillToAddressID == null ) && this.oneTimeCust.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Billing Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if((this.salesOrder.ShipmentToAddressID == "" || this.salesOrder.ShipmentToAddressID == 0 || this.salesOrder.ShipmentToAddressID == null ) && this.oneTimeCust.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Shipping Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.soDate == "" || this.salesOrder.soDate == 0 || this.salesOrder.soDate == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.DueDate == "" || this.salesOrder.DueDate == 0 || this.salesOrder.DueDate == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            } else {
                console.log("submit due date : ", this.salesOrder.DueDate);
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date must be equal or after Sales Order Date",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(this.selectPaymentTerm.id == "" || this.selectPaymentTerm.id == 0 || this.selectPaymentTerm.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Payment Term first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(this.selectDeliveryType.id == "" || this.selectDeliveryType.id == 0 || this.selectDeliveryType.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Type first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return true;
            }

            if(this.customerCategory == null){
                this.$vs.notify({
                    title: "Error",
                    text: "Please select distribution channel first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return;
            }

            // pricing group
            if(this.pricingGroup.selected == null){
                this.$vs.notify({
                    title: "Error",
                    text: "Please select pricing group first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return;
            }

            // zone
            if(this.zone == null){
                this.$vs.notify({
                    title: "Error",
                    text: "Please select zone first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return;
            }

            // SO type
            if(this.soType == null){
                this.$vs.notify({
                    title: "Error",
                    text: "Please select sales order type first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return;
            }

            this.$vs.loading();
            console.log(this.pricingGroup)
            // Params preparation
            var params = {
                sales_order_code: this.salesOrder.Code,
                po_reference_code: this.salesOrder.PoReferenceCode,
                delivery_order_code: this.salesOrder.DeliveryOrderCode,
                sales_channel_code: this.salesOrder.SalesChannel.Code,
                territory_code: this.territory.code,
                company_code: this.salesOrder.Company.Code,
                customer_code: this.salesOrder.Customer.Code,
                promo_code: this.promotionCode,
                bill_to_address_id: this.salesOrder.BillToAddressID,
                shipment_to_address_id: this.salesOrder.ShipmentToAddressID,
                due_date: this.salesOrder.DueDate,
                date: this.salesOrder.soDate,
                tax_rate: this.selectTax.Rate,
                allow_partial: parseInt(this.salesOrder.AllowPartial),
                payment_term_id: this.selectPaymentTerm.id,
                delivery_type_id: this.selectDeliveryType.id,
                delivery_type_code: this.selectDeliveryType.code,
                subtotal_charge: this.salesOrder.SubtotalCharge,
                is_onetime: this.isOneTime,
                onetimecust_id: this.oneTimeCust.id,
                customer_category_id: this.customerCategory.ID,
                pricing_group_id: this.pricingGroup.selected[0].ID,
                zone_id: this.zone.ID,
                so_type: this.soType.name,
            };

            params.notes = "";
            params.items_code = [];
            params.items_name = [];
            params.items_uom = [];
            params.items_quantity_request = [];
            params.items_quantity_adjusted = [];
            params.items_price = [];
            for (let i = 0; i < this.salesOrderLines.length; i++) {
                let qty_per_HU = this.salesOrderLines[i].items_hu.split('.');
                for (let j = 0; j < this.salesOrderLines[i].hu_labels.length; j++) {
                    params.items_code.push(this.salesOrderLines[i].sku_code);
                    params.items_name.push(this.salesOrderLines[i].sku_name);
                    params.items_uom.push(this.salesOrderLines[i].hu_labels[j].unit);
                    params.items_quantity_request.push(0);
                    params.items_quantity_adjusted.push(parseInt(qty_per_HU[j]));
                    params.items_price.push(0);
                }
            }

            console.log('adjusted / new params', params)

            await this.$http
            .post("/api/v1/sales-order/simple-store", params)
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "Sales Order has been added",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.CancelForm();
                    this.setDefaultDate();
                    this.getOptionTax();
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        setDefaultDate() {
            var day = new Date();
            var nextDay = new Date(day);
            nextDay.setDate(day.getDate() + 1); 

            this.salesOrder.soDate = day
            this.salesOrder.DueDate = nextDay
        },
        getTime(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer/work-time/"+id).then(resp => {
                if (resp.code == 200) {
                   var day = new Date();
                    var nextDay = new Date(day);
                    nextDay.setDate(day.getDate() + 1);
                    nextDay.setHours(resp.data[0])
                    nextDay.setMinutes(resp.data[1])
                    this.salesOrder.DueDate = nextDay
                }
                this.$vs.loading.close();
            });
        },
        async getOptionTerritory() {
            await this.$http.get("/api/v1/master/territory").then(resp => {
                this.optionTerritory = resp.data.records;
                // this.territory = resp.data.records[0];
                this.territory = null;
            });
        },
        async getOptionSalesChannel() {
            // console.log('this.$userLogin', this.$userLogin)
            let superadmin, url;
            await this.$userLogin.roles.filter(function(item) {
                switch (item.Name) {
                    case "superadmin":
                        url = "/api/v1/sales-channel/list";
                        superadmin = true;
                        break;
                    case "admin":
                        url = "/api/v1/sales-channel/personal";
                        superadmin = false;
                        break;
                }
            });
            // this.$http.get("/api/v1/sales-channel/personal").then(
            // this.$http.get("/api/v1/sales-channel/list").then(
            this.$http.get(url).then(
                resp => {
                    // console.log("3039",resp)
                    if(resp.code == 200){
                        this.optionSalesChannel = []
                        // this.optionSalesChannel.push({code:'All'})
                        for(var salesChannel = 0; salesChannel<resp.data.length; salesChannel++){
                            this.optionSalesChannel.push(resp.data[salesChannel])
                        }
                        
                        this.salesChannel = this.optionSalesChannel[0];
                        
                        // Default sales channel nya gmn?
                        // if(superadmin){
                        //     this.salesChannel = resp.data[0];
                        // }else{
                        //     this.salesChannel = this.optionSalesChannel.filter((v) => {
                        //         // return /  set default sales channel yang sesuai dengan user login
                        //         return v.ID == 2;
                        //     });
                        // }
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        async getOptionCustomerCategory() {
            await this.$http.get("/api/v1/master/customer-category-all").then(
                resp => {
                    if(resp.code == 200){
                        this.optionCustomerCategory = []
                        // this.optionCustomerCategory.push({name:'All'})
                        for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
                            this.optionCustomerCategory.push(resp.data[customerCategory])
                        }
                        // this.customerCategory = this.optionCustomerCategory[0];
                        this.customerCategory = null;
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        handleClose() {
            // window.scrollTo(0, 0);
            this.optionItemAvailableShow     = false;
            this.optionCustomerShow          = false;
            this.optionBillToShow            = false;
            this.optionShipToShow            = false;
            this.optionPromotionCodeShow     = false;
            this.optionReservationCodeShow   = false;
            this.detailStepShow              = false;
            this.detailTotalDiscountStepShow = false;
            this.showOTCForm                 = false;
        },
        async getOptionSalesID() {
            let superadmin;
            await this.$userLogin.roles.filter(function(item) {
                if(item.Name == "superadmin"){
                    superadmin = true;
                }else{
                    superadmin = false;
                }
            });
            this.$http.get("/api/v1/sales-order/personals").then(
                resp => {
                    if(resp.code == 200){
                        this.optionSalesPersonal = []
                        let data = resp.data.salesman
                        for(var idx = 0;idx<data.length;idx++){
                            this.optionSalesPersonal.push(data[idx])
                        }
                        if (!superadmin) {
                            // set default Sales ID jika bukan superadmin
                            this.salesPersonal = this.optionSalesPersonal.filter((v) => {
                                return v.ID == this.$userLogin.id;
                            });
                        }
                    } else {
                        console.log(resp.message)
                    }
                }
            );
        },
        handleCustOnetime(){
            let form_empty;
            console.log(this.customerCategory)
            // Buat save customer onetime disini, ke tabel baru
            form_empty = (this.oneTimeCust.name == null || this.oneTimeCust.name == "");
            form_empty = (this.oneTimeCust.contact_person == null || this.oneTimeCust.contact_person == "");
            form_empty = (this.oneTimeCust.nik == null || this.oneTimeCust.nik == "");
            form_empty = (this.oneTimeCust.phone == null || this.oneTimeCust.phone == "");
            
            if(form_empty){
                this.$vs.notify({
                    title: "Error",
                    text: "Please fill form correctly !",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return;
            }

            // this.$validator.validateAll().then((result) => {
            //     if (result) {
                    this.$vs.loading();
                    let params, url;
                    url = this.oneTimeCust.id != null ? "/api/v1/sales-order/onetime_cust/" + this.oneTimeCust.id : "/api/v1/sales-order/onetime_cust/store";
                    params = {
                        // Default by Customer - ONETIME CUSTOMER
                        type: this.salesOrder.Customer.Type,
                        customer_id: this.salesOrder.Customer.ID,
                        payment_method_id: this.salesOrder.Customer.PaymentMethodID,

                        // Default init value, updated after SO created
                        payment_term_id: 0,
                        
                        // Value by input
                        name: this.oneTimeCust.name,
                        country: this.oneTimeCust.address.countryName,
                        province: this.oneTimeCust.address.provinceName,
                        city: this.oneTimeCust.address.cityName,
                        district: this.oneTimeCust.address.districtName,
                        sub_district: this.oneTimeCust.address.subdistrictName,
                        address: this.oneTimeCust.address.address,
                        postal_code: this.oneTimeCust.address.postalCode,
                        contact_person: this.oneTimeCust.contact_person,
                        nik: this.oneTimeCust.nik,
                        phone: this.oneTimeCust.phone,
                        tax_id: this.oneTimeCust.tax_id,
                    };
                    console.log('params', params)
                    this.$http.post(url, params)
                    .then(resp => {
                        console.log(url, resp);
                        if (resp.code == 200) {
                            /* 
                                Sukses 
                                1. set column field Customer Name
                                2. set column field Bill to
                                3. Set column field Ship to 
                            */
                            this.oneTimeCust.id = this.oneTimeCust.id == null ? resp.data.ID : this.oneTimeCust.id;
                            this.salesOrder.Customer.Name = this.oneTimeCust.name;

                            this.salesOrder.BillAddress.Address = this.oneTimeCust.address.address;
                            this.salesOrder.BillAddress.City = this.oneTimeCust.address.cityName;
                            
                            this.salesOrder.ShipAddress.Address = this.oneTimeCust.address.address;
                            this.salesOrder.ShipAddress.City = this.oneTimeCust.address.cityName;

                            this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check-circle",
                                });
                            this.handleClose();
                        } else {
                            this.$vs.notify({
                                title: "Error",
                                text: resp.message,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                        }
                        this.$vs.loading.close();
                    });
            //     }
            // });
        },
        setDataAddress(val) {
            this.oneTimeCust.address = val;
        },
        showOTForm(){
            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.showOTCForm = true;
        },
        calc_itemsLineQty(index){
            var uomConv, sumHU, sumUOM = 0;
            sumHU = this.salesOrderLines[index].items_hu.split(".");
            uomConv = this.salesOrderLines[index].hu_labels;
            
            if(sumHU.length != uomConv.length){
                this.salesOrderLines[index].items_qty = 0;
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid input! Mohon input sesuai jumlah HU Label!",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }else{
                for (let i = 0; i < sumHU.length; i++) {
                    sumUOM += parseInt(sumHU[i] * parseInt(uomConv[i].amount_uom));
                    this.salesOrderLines[index].items_qty = sumUOM;
                }
                this.calculated = false;
            }
        },
        calc_itemsLineQtyConv(index){
            var uomConv, sumHU, amount_uom, sumUOM = 0;
            sumHU = [];
            uomConv = this.salesOrderLines[index].hu_labels;
            sumUOM = parseInt(this.salesOrderLines[index].items_qty);

            for (let i = 0; i < uomConv.length; i++) {
                amount_uom = parseInt(uomConv[i].amount_uom);
                if((sumUOM / amount_uom) > 0){
                    sumHU.push(parseInt(sumUOM / amount_uom));
                    sumUOM = sumUOM % amount_uom;
                }else{
                    sumHU.push(0);
                }
            }

            this.calculated = false;
            this.salesOrderLines[index].items_hu = sumHU.join('.');
        }
    },
};
</script>
